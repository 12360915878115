:root {
    --color_dark: #06305C;
    --color_prime: #005591;
    --color_prime7: #4C88B2;
    --color_prime5: #7FAAC8;
    --color_prime3: #B2CCDE;
    --color_cyan: #00A0E1;
    --color_hover: #007AB9;
    --color_gray: #6E7878;
    --color_gray7: #99A0A0;
    --color_gray5: #B6BBBB;
    --color_gray3: #D3D6D6;
    --color_yellow: #ffd200;
    --color_orange: #dc7800;
    --color_red: #E1000F;
    --color_red7: #f7ebeb;
    --color_green: #009B3C;
    --color_green7: #4CB976;
    --color_draft: #BDC8D7;

  }

.text-dark{
  color: var(--color_dark);
}
