

.unsupported .container {
  width: 100%;
  min-width: 300px;
}
.unsupported-browser {
  background: #fff;
  margin: 35px auto;
  width: 100%;
  position: relative;
  padding: 20px 30px;
  text-align: left;
}
.unsupported-browser h1 {
  font-size: 2em;
  font-weight: 0;
}
.unsupported-browser h2 {
  border: 0;
}
.unsupported-browser li {
  margin-bottom: 5px;
  margin-top: 15px;
}
.unsupported-browser li a {
  color: var(--color_cyan);
}
.unsupported-browser li a:hover {
  color: #007bc3;
}
.unsupported hr{
  margin-top: 50px;
}
.unsupported-message {
  font-size: 0.9em;
  margin-top: 40px;
  margin-bottom: 30px;
}
.unsupported-message li {
  margin-bottom: 5px;
}


.bg-results{
  background-color: #f0f0f0;
}
.linde-select__menu {
  z-index: 999 !important;
}
/* .animate-new-elements * {
  max-height: 0;
  opacity: 0;
  animation: grow 1s ease-in-out forwards;
  -webkit-animation: grow 1s ease-in-out forwards;
} */
.Select.is-open {
  z-index: 1000 !important;
}

.mw-10{
  min-width: 121px;
}
.linde-input-shadow{
  -webkit-box-shadow: inset 2px 2px 6px 0px #B9CDD7;
  box-shadow: inset 2px 2px 6px 0px #B9CDD7;
}
.linde-bold{
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
button:focus {
  outline: none!important;
}

.linde-tooltip{
  max-width: 300px;
  opacity: 1!important;
}

.btn-with-icon{
  @apply flex flex-wrap content-center justify-end;
}
.btn-with-icon-right{
  @apply flex flex-wrap content-center justify-start;
}
.btn {
  @apply py-2 px-4 h-12;
}

.btn-prime {
  @apply text-white;
  background-color: #00A0E1;
}
.btn-prime:hover {
  background-color:#007AB9;
  /**/
}
.btn-prime.disabled {
  --text-opacity: 1;
  color: var(--color_gray);
  background-color: var(--color_gray3);
}

.btn-sec {
  @apply text-base;
  background-color: #D2E1EB;

}
.btn-sec:hover {
  background-color:#B3CCDE;
  /*  */
}
.btn-sec.disabled {
  --text-opacity: 1;
  color: var(--color_gray);
  background-color: var(--color_gray3);
}

.btn-link {
  color: #00A0E1;

}
.btn-link:hover {
  color: #007AB9;
  /* */
}

.headline{
  @apply text-xl font-bold leading-none break-normal;
}

.sub-headline{
  @apply text-lg font-bold leading-none break-normal;
}

  .main-bg-color{
    background-color: #EAF0F3;
  }
  .paragraph{
    @apply  font-normal w-full pt-1 text-base leading-snug;
  }
  .main-linde-content{
    @apply px-10 py-10;
  }

  .linde-container{

    max-width: 1600px;
    @apply ml-auto mr-auto flex justify-center;

  }

  .padding-outter-bottom {
    @apply pb-24;
  }

  .padding-outter-top {
    @apply pt-24;
  }


  .logo-mfm {
    z-index: 1111;
    -webkit-box-shadow: 0px 10px 30px rgb(0 0 0 / 5%);
    box-shadow: 0px 10px 30px rgb(0 0 0 / 5%);
    -webkit-transition: 0.4s ease;
    transition: 0.4s ease;
    position: absolute;
    left: 0px;
    top: 0;
    width: 100px;
    height: 150px;
    text-align: center;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    background: #3f7abc;
}

.logo-mfm img {
  max-width: 100%;
  margin-bottom: 10px;
  width: 80%;
}

  @screen md {


    .logo-mfm {
      width: 130px;
      height: 160px;
  }

    .paragraph{
      @apply  font-normal w-full pt-2 text-base leading-snug;
    }

    .linde-container{
      width: 100%;
    }


    .padding-outter-bottom {
      @apply pb-32;
    }

    .padding-outter-top {
      @apply pt-32;
    }
    .headline{
      @apply text-2xl font-bold leading-none break-normal;
    }

    .sub-headline{
      @apply text-xl font-bold leading-none break-normal;
    }
  }

  @screen lg {


    .paragraph{
      @apply  font-normal w-full pt-3 text-base leading-snug;
    }
    .linde-container{

      width: 1000px;
    }

    .padding-outter-bottom {
      @apply pb-40;
    }

    .padding-outter-top {
      @apply pt-40;
    }
    .headline{
      @apply text-3xl font-bold leading-none break-normal;
    }

    .sub-headline{
      @apply text-2xl font-bold leading-none break-normal;
    }


  }

  @screen xl {
    .paragraph {
      @apply  font-normal w-full pt-3 text-base leading-snug;
    }

    .linde-container{

      width: 1100px;
    }

    .padding-outter-bottom {
      @apply pb-48;
    }

    .padding-outter-top {
      @apply pt-48;
    }

    .headline{
      @apply text-4xl font-bold leading-none break-normal;
    }

    .sub-headline{
      @apply text-3xl font-bold leading-none break-normal;
    }
  }


  @screen xxl {
    .paragraph{
      @apply  font-normal w-full pt-3 text-base leading-snug;
    }

    .linde-container{

      width: 1250px;
    }

    .padding-outter-bottom {
      @apply pb-48;
    }

    .padding-outter-top {
      @apply pt-48;
    }
    .headline{
      @apply text-4xl font-bold leading-none break-normal;
    }

    .sub-headline{
      @apply text-3xl font-bold leading-none break-normal;
    }

  }

  @media only screen and (max-width: 361px) {
    .paragraph{
      @apply text-sm ;
    }
  }


  .map-container {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    }

    .sidebar {
    background-color: rgba(35, 55, 75, 0.9);
    color: #ffffff;
    padding: 6px 12px;
    font: 15px/24px monospace;
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    margin: 12px;
    border-radius: 4px;
    }

    .marker {
      background-image: url('../img/water-pump.svg');
      background-size: contain;
      background-repeat: no-repeat;
      width: 10px;
      height: 30px;
      transition: opacity 0.2s ease;
      cursor: pointer!important;
    }
    .marker:hover {

      opacity: 0.8;
    }

    .mapboxgl-popup {
      max-width: 200px;
    }

    .mapboxgl-popup-content {
      text-align: center;
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji", sans-serif;
    }

.scroll-margin-top{
  scroll-margin-top: 100px;
}

#btt-button {
  display: inline-block;
  background-color: #3f7abc;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 4px;
  position: fixed;
  bottom: 30px;
  right: 30px;
  transition: background-color .3s,
    opacity .5s, visibility .5s;
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

#btt-button:hover {
  cursor: pointer;
  background-color: #85a5ca;
}

#btt-button.show {
  opacity: 1;
  visibility: visible;
}

.js-plotly-plot .plotly .modebar {
  display: absolute;

}
.modebar-container{
top: 20px!important;
right: 100px!important;
}
.js-plotly-plot .plotly .modebar-group {
float: none!important;
display: flex!important;
box-sizing: border-box!important;
padding-left: 8px!important;
position: relative!important;
vertical-align: middle!important;
white-space: nowrap!important;
}
.js-plotly-plot .plotly .modebar {
position: absolute!important;
top: 10px;
right: 2px;
left: 50%;
transform: translateX(-50%);
display: flex!important;
justify-content: flex-end!important;
flex-wrap: wrap!important;
}
.js-plotly-plot{
font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"!important;
}
.bg-mfm-red{
  background-color: #dc3545;
}
/* .js-plotly-plot .legend{
  display: none!important;
} */

/* #menu {
  position: absolute;
  background: #efefef;
  padding: 10px;
  font-family: 'Open Sans', sans-serif;
  } */

  .visible{
    visibility: visible;
  }

  .invisible{
    visibility: hidden;
  }