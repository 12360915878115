

/* @font-face {
    font-family: 'lindeLight';
    src: url('./fonts/LindeDaxLigWeb.eot');
    src: url('./fonts/LindeDaxLigWeb.woff') format('woff')
}

@font-face {
    font-family: 'lindeMedium';
    src: url('./fonts/LindeDaxMedWeb.eot');
    src: url('./fonts/LindeDaxMedWeb.woff') format('woff')
}

@font-face {
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
    src: url('./fonts/LindeDaxRegWeb.eot');
    src: url('./fonts/LindeDaxRegWeb.woff') format('woff')
}

@font-face {
    font-family: 'lindeSemiLight';
    src: url('./fonts/LindeDaxSemiLigWeb.eot');
    src: url('./fonts/LindeDaxSemiLigWeb.woff') format('woff')
} */



h1,
h2,
h3,
h4,
h5 {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  margin: 0;
  padding: 0;
  color: var(--color_dark);
}

body,
p,
li,
ul,
body span {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

}

a {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  transition: 0.5s;
}

a:hover,
a:active,
a:focus {
  outline: none;
  text-decoration: none;
}

p {
  padding: 0;
  margin: 0;
}
